import { IconButton, Circle, useColorMode, Box } from '@chakra-ui/react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Link as ChakraLink,
} from '@chakra-ui/react';
import profile from '../components/images/profile.webp';

export default function Home() {
  const { colorMode } = useColorMode();
  const iconColor = colorMode === 'light' ? 'black' : 'white';
  const bgColor = colorMode === 'light' ? 'gray.200' : 'gray.700';
  const textColor = colorMode === 'light' ? 'black' : 'white';

  const titles = ['Full Stack Developer', 'Freelancer'];
  const maxLength = Math.max(...titles.map((title) => title.length));
  const paddedTitles = titles.map((title) => title.padEnd(maxLength, ' '));

  const [title, setTitle] = useState(paddedTitles[0]);
  const [displayText, setDisplayText] = useState('');
  const [isAdding, setIsAdding] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isAdding) {
        if (index < title.length) {
          setDisplayText((prev) => prev + title[index]);
          setIndex((prev) => prev + 1);
        } else {
          setIsAdding(false);
          setIndex(index - 1);
        }
      } else {
        if (index >= 0) {
          setDisplayText((prev) => prev.slice(0, -1));
          setIndex((prev) => prev - 1);
        } else {
          setIsAdding(true);
          setTitle((prev) =>
            prev === paddedTitles[0] ? paddedTitles[1] : paddedTitles[0]
          );
          setIndex(0);
        }
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [index, isAdding, title, paddedTitles]);

  const buttonStackDirection = useBreakpointValue({
    base: 'column',
    md: 'row',
  });

  return (
    <Stack
      minH={'100vh'}
      direction={{ base: 'column', md: 'row' }}
      data-aos='fade-up'
      data-aos-offset='200'
      position='relative'
    >
      <Flex
        p={5}
        flex={1}
        align={'center'}
        justify={'center'}
        marginTop={'10%'}
      >
        <Stack spacing={1} w={'full'} maxW={'lg'} alignItems='center'>
          <Text>Hey, I'm</Text>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              display='inline-block'
              height='50px'
              lineHeight='50px'
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'blue.400',
                zIndex: -1,
              }}
            >
              Kavindu Chinthana
            </Text>
            <br />
          </Heading>
          <Text
            color={'blue.400'}
            as={'span'}
            display='inline-block'
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
            fontWeight={'bold'}
          >
            I'm {displayText}
          </Text>
          <Text fontSize={{ base: 'md', lg: 'lg' }} color={textColor} mt='2'>
            I am Kavindu Gayan Chinthana, a third-year undergraduate at the
            University of Moratuwa, majoring in Computer Science and
            Engineering. I have a passion for full-stack development and am
            interested in creating innovative solutions using cutting-edge
            technologies. I completed an internship as a Software Engineer at
            CodeGen International Pvt Ltd. My areas of expertise include
            front-end development using JavaScript frameworks such as React and
            Angular, as well as back-end development using Node.js and Spring
            Boot. Additionally, I have experience with Flutter and DevOps.
          </Text>
          <Stack
            direction={buttonStackDirection}
            spacing={4}
            justify='center'
            align='center'
          >
            <Stack direction='row' spacing={4} mb={4}>
              <Circle size='60px' bg={bgColor} color={iconColor}>
                <IconButton
                  as='a'
                  href='https://github.com/kgchinthana'
                  aria-label='GitHub'
                  icon={<FaGithub />}
                  variant='ghost'
                  size='lg'
                  _hover={{ color: 'blue.400' }}
                  target='_blank'
                  rel='noopener noreferrer'
                />
              </Circle>
              <Circle size='60px' bg={bgColor} color={iconColor}>
                <IconButton
                  as='a'
                  href='https://www.linkedin.com/in/kavindu-chinthana-63940a233/'
                  aria-label='LinkedIn'
                  icon={<FaLinkedin />}
                  variant='ghost'
                  size='lg'
                  _hover={{ color: 'blue.400' }}
                  target='_blank'
                  rel='noopener noreferrer'
                />
              </Circle>
            </Stack>
            <Button
              rounded={'full'}
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              paddingX={'32px'}
              paddingY={'12px'}
              aria-label='Get In Touch'
              onClick={() => {
                const contactSection =
                  document.getElementById('contact-section');

                if (contactSection) {
                  contactSection.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }}
            >
              Get In Touch
            </Button>
            <Button
              rounded={'full'}
              paddingX={'32px'}
              paddingY={'12px'}
              aria-label='Get CV'
            >
              <ChakraLink
                href='https://drive.google.com/file/d/1Q7pnNoh0BkJk-vBfLlb5Hnufaaf_DsnA/view?usp=sharing'
                isExternal
                textDecoration='none'
              >
                Get CV
              </ChakraLink>
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          borderRadius='full'
          boxSize={{ base: '300px', md: '400px', lg: '500px' }}
          src={profile}
        />
      </Flex>
    </Stack>
  );
}
