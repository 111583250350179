'use client';
import { Box, Grid, GridItem, Text, VStack, Image, useColorModeValue } from '@chakra-ui/react';
import presidents_college from '../components/images/presidents_college.png';
import sivali_central from '../components/images/sivali_central.png';
import gce_al from '../components/images/gce_al.png';
import bsc_engineering from '../components/images/bsc_engineering.png';
import cg_logo from '../components/images/cg_logo.png';
// import gpa from '../components/images/gpa.png';
import web_dev from '../components/images/web_dev.png';
import machine_learning from '../components/images/machine_learning.png';
import explore_tech from '../components/images/explore_tech.png';
import photography from '../components/images/photography.png';
import hometown from '../components/images/hometown.png';
import programming from '../components/images/programming.png';

export default function InfoGrid() {
  const bgColor = useColorModeValue('white', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const shadow = useColorModeValue('lg', 'dark-lg');

  const infoItems = [
    {
      imageSrc: bsc_engineering,
      label: 'BSc. in Engineering UG\nCSE (CSE) UoM',
    },
    {
      imageSrc: cg_logo,
      label: 'CodeGen International\nSoftware Engineer Intern',
    },
    {
      imageSrc: presidents_college,
      label: "President's College, Embilipitiya ",
    },
    {
      imageSrc: sivali_central,
      label: 'Sivali Central Collage, Rathnapura',
    },
    {
      imageSrc: gce_al,
      label: 'GCE A/L 2019\nDR - 11 IR - 213',
    },
    // {
    //   imageSrc: gpa,
    //   label: 'Current GPA\n3.4 / 4.0',
    // },
    {
      imageSrc: web_dev,
      label: 'Web Development:\n3+ yrs experience',
    },
    {
      imageSrc: machine_learning,
      label: 'Machine learning:\n1+ yrs experience',
    },
    {
      imageSrc: explore_tech,
      label: 'Passionate about discovering:\nCutting-Edge Technologies',
    },
    {
      imageSrc: photography,
      label: 'Favorite Activities:\nPhotography and Traveling',
    },
    {
      imageSrc: hometown,
      label: 'From:\nEmbilipitiya, Sri Lanka',
    },
    {
      imageSrc: programming,
      label: 'Obsessed with:\nCoding and Development',
    },
  ];

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius={'lg'}
      maxW={'6xl'}
      mx={'auto'}
      shadow={shadow}
    >
      <Grid
        templateColumns={{
          base: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        gap={6}
      >
        {infoItems.map((item, index) => (
          <GridItem
            key={index}
            bg={cardBgColor}
            borderRadius={'lg'}
            p={4}
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
            shadow={shadow}
          >
            <VStack spacing={3}>
              <Image
                src={item.imageSrc}
                boxSize={'50px'}
                objectFit={'contain'}
                alt={item.label}
              />
              <Text fontSize={'md'} fontWeight={'bold'} color={textColor}>
                {item.label}
              </Text>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}
