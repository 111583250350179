import {
  Heading,
  Box,
  Text,
  Button,
  Divider,
  SimpleGrid,
  Card,
  Image,
  Stack,
  ButtonGroup,
  CardFooter,
  CardBody,
  Link,
  Flex,
} from '@chakra-ui/react';
import { FaGithub } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import carcare from '../components/gif/carcare.gif';
import emailclient from '../components/gif/emailclient.gif';
import rpal from '../components/gif/rpal.gif';
import pos from '../components/gif/pos.gif';
import kcmail from '../components/gif/kcmail.gif';
import airline from '../components/gif/airline.gif';
import hotel_web_page from '../components/gif/sandalwood_cottage.gif';
import zara from '../components/gif/zara.gif';

function ProjectCard({
  image,
  title,
  description,
  repoLink,
  detailsLink,
  deployedLink,
}) {
  return (
    <Card maxW='sm' maxH='1000' height='100%'>
      <CardBody>
        <Image
          src={image}
          alt={`${title} gif`}
          borderRadius='lg'
          width='100%' // Ensures the image takes up the full width of the card
          height='265px' // Set a fixed height for consistency
        />{' '}
        <Stack mt='6' spacing='3'>
          <Heading size='md'>{title}</Heading>
          <Text>{description}</Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <ButtonGroup spacing='2'>
          <Button variant='solid' colorScheme='blue'>
            <Link href={repoLink} isExternal>
              GitHub Repo
            </Link>
          </Button>
          {deployedLink ? (
            <Button variant='solid' colorScheme='green'>
              <Link href={deployedLink} isExternal>
                Live Demo
              </Link>
            </Button>
          ) : (
            <Button variant='solid' colorScheme='gray' isDisabled>
              Live Demo Unavailable
            </Button>
          )}
          {detailsLink && (
            <Button variant='ghost' colorScheme='blue'>
              <Link href={detailsLink} isExternal>
                More Details
              </Link>
            </Button>
          )}
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
}

function Projects() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const projects = [
    {
      image: hotel_web_page,
      title: 'Hotel Management System',
      description:
        'Developed a comprehensive hotel management system with features including room reservation, travel booking, safari booking, and hotel details showcasing. Built using Angular, Spring Boot, MySQL, and Firebase. This project was developed for a client, so further details cannot be disclosed.',
      repoLink: 'https://github.com/kgchinthana/sandalwood-cottage-backend.git',
      deployedLink: 'https://sandalwoodcottage.com/', // No deployed link for this project
    },
    {
      image: carcare,
      title: 'Car Care 360',
      description:
        "We developed a Customer Relationship Management (CRM) system specifically for automobile maintenance. The objective of this project is to improve customer satisfaction by streamlining the automobile servicing procedure. The project's key aspects included. Implemented using MERN stack.",
      repoLink: 'https://github.com/kgchinthana/CarCare360CRMSyestem.git',
      deployedLink: 'https://car-care-360-jl9d.onrender.com/', // Example deployed link
    },
    {
      image: zara,
      title: 'Zara Chat Bot',
      description:
        'Zara is a chatbot designed to answer PDF-based questions. Built with React for the frontend and Python for the backend, it uses a pre-trained model to extract and analyze text from PDFs, providing accurate responses. This project showcases my skills in integrating AI with full-stack development for practical applications.',
      repoLink: 'https://github.com/kgchinthana/CarCare360CRMSyestem.git',
      deployedLink: '', // Example deployed link
    },
    {
      image: emailclient,
      title: 'Email Client',
      description:
        'The primary objective of this project was to design, develop, and implement a versatile and efficient command-line-based email client in Java. The project aimed to provide a comprehensive email management solution with a strong focus on recipient management, email sending, and automation.',
      repoLink: 'https://github.com/kgchinthana/Email-Client.git',
      deployedLink: '', // No deployed link for this project
    },
    {
      image: rpal,
      title: 'RPAL Interpreter',
      description:
        'The RPAL compiler project is focused on creating a robust tool for developers to work with the RPAL programming language. RPAL is a language that requires a compiler to convert its code into executable instructions. We implemented a lexical analyzer and parser for the RPAL programming language.',
      repoLink: 'https://github.com/kgchinthana/RPAL-Interpreter.git',
      deployedLink: '', // No deployed link for this project
    },
    {
      image: airline,
      title: 'Airline Reservation System',
      description:
        'We designed and implemented a comprehensive database system for airline reservation system. The system allows passengers to book flights online and provides various features, including seat selection, discounts for registered users, and dynamic pricing based on traveler class.',
      repoLink: 'https://github.com/kgchinthana/AirlineReservationSystem.git',
      deployedLink: '', // No deployed link for this project
    },
    {
      image: pos,
      title: 'Inventory Management and POS System',
      description:
        'Designed and developed a robust Inventory Management and POS System for OXI CROP SCIENCE using cutting-edge technologies including React, Node.js, Express.js, and MySQL database. The system seamlessly integrates inventory tracking and point-of-sale functionalities, providing the company with a streamlined and efficient solution for managing their agricultural products.',
      repoLink: 'https://github.com/kgchinthana/Oxi-Crop-Science.git',
      deployedLink: '', // No deployed link for this project
    },
    {
      image: kcmail,
      title: 'KC Mail',
      description:
        'I developed a comprehensive email client using Spring Boot for the backend and React for the frontend. The application includes a company management system to automate birthday wishes and other email communications, enhancing internal communication and fostering a positive workplace culture.',
      repoLink: 'https://github.com/kgchinthana/KC-Email.git',
      deployedLink: '', // No deployed link for this project
    },
  ];

  return (
    <Box py={12} data-aos='fade-up' data-aos-offset='200'>
      <Text fontSize='xl' color={'gray.500'}>
        Recent Projects
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Flex p={2} flex={1} align={'center'} justify={'center'}>
          <Heading fontSize={{ base: '4xl', md: '4xl' }}>My Portfolio</Heading>
        </Flex>
        <Flex p={2} flex={1} align={'center'} justify={'center'}>
          <Button colorScheme='twitter' leftIcon={<FaGithub />}>
            <Link
              href='https://github.com/kgchinthana?tab=repositories'
              isExternal
            >
              GitHub
            </Link>
          </Button>
        </Flex>
      </Stack>

      <SimpleGrid
        spacing={4}
        alignItems={'center'}
        justifyItems={'center'}
        templateColumns={{
          base: 'repeat(1, minmax(200px, 1fr))',
          md: 'repeat(2, minmax(200px, 1fr))',
          lg: 'repeat(3, minmax(200px, 1fr))',
        }}
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            image={project.image}
            title={project.title}
            description={project.description}
            repoLink={project.repoLink}
            detailsLink={project.detailsLink}
            deployedLink={project.deployedLink}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Projects;
