'use client';
import { Image, Stack, Text, Heading, Flex, Box } from '@chakra-ui/react';
import about from '../components/images/file.webp';

export default function AboutMe() {
  return (
    <Stack
      minH={'100vh'}
      direction={{ base: 'column', lg: 'row' }}
      spacing={8}
      data-aos='fade-up'
      data-aos-offset='200'
    >
      <Flex
        p={8}
        flex={1}
        align={'center'}
        justify={'center'}
      >
        <Box boxSize={{ base: '300px', md: '400px', lg: '500px' }}>
          <Image
            alt={'Profile Picture'}
            objectFit={'cover'}
            boxSize={'100%'}
            src={about}
          />
        </Box>
      </Flex>
      <Flex
        p={8}
        flex={3}
        align={'center'}
        justify={'center'}
      >
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <Text as={'span'}>Who am I?</Text>
          </Heading>
          <Text
            fontSize={{ base: 'md', lg: 'lg' }}
            color={'gray.500'}
            textAlign={{ base: 'center', lg: 'center' }}
          >
            I'm a Computer Science and Engineering undergraduate with a keen
            interest in full-stack development. With a mastery of both front-end
            and back-end technologies, I'm driven to solve complex problems and
            keep pace with the latest developments in the industry. I'm
            convinced that continuous learning and collaboration are the key to
            advancing the IT world, and I'm ready to use my skills to make a
            positive, meaningful impact in the technology sector.
          </Text>
        </Stack>
      </Flex>
      
    </Stack>
  );
}
