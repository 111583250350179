'use client';
import { Box, Grid, GridItem, Image, Text, VStack, Heading, useColorModeValue } from '@chakra-ui/react';

// Importing images
import react from '../components/images/react.png';
import angular from '../components/images/angular.png';
import react_native from '../components/images/react_native.png';
import next from '../components/images/next.png';
import css from '../components/images/css.png';
import html from '../components/images/html.png';
import javascript from '../components/images/javascript.png';
import node from '../components/images/node.png';
import springboot from '../components/images/springboot.png';
import postgresql from '../components/images/postgresql.png';
import mongodb from '../components/images/mongodb.png';
import mysql from '../components/images/mysql.png';
import c from '../components/images/c.png';
import cpp from '../components/images/cpp.png';
import java from '../components/images/java.png';
import typescript from '../components/images/typescript.png';
import python from '../components/images/python.png';
import dart from '../components/images/dart.png';
import github from '../components/images/github.png';
import gitlab from '../components/images/gitlab.png';
import figma from '../components/images/figma.png';
import firebase from '../components/images/firebase.png';
import flutter from '../components/images/flutter.png';
import teamwork from '../components/images/teamwork.png';
import english from '../components/images/english.png';
import sinhala from '../components/images/sinhala.png';

// Define the technologies for each category
const expertiseData = {
  Frontend: [
    { name: 'Angular', imgSrc: angular },
    { name: 'React', imgSrc: react },
    { name: 'React Native', imgSrc: react_native },
    { name: 'Next.js', imgSrc: next },
    { name: 'CSS', imgSrc: css },
    { name: 'HTML', imgSrc: html },
    { name: 'JavaScript', imgSrc: javascript },
  ],
  Backend: [
    { name: 'Node.js', imgSrc: node },
    { name: 'Spring Boot', imgSrc: springboot },
    { name: 'PostgreSQL', imgSrc: postgresql },
    { name: 'MongoDB', imgSrc: mongodb },
    { name: 'MySQL', imgSrc: mysql },
  ],
  Languages: [
    { name: 'C', imgSrc: c },
    { name: 'C++', imgSrc: cpp },
    { name: 'Java', imgSrc: java },
    { name: 'TypeScript', imgSrc: typescript },
    { name: 'Python', imgSrc: python },
    { name: 'Dart', imgSrc: dart },
  ],
  'Other Skills': [
    { name: 'GitHub', imgSrc: github },
    { name: 'GitLab', imgSrc: gitlab },
    { name: 'Figma', imgSrc: figma },
    { name: 'Firebase', imgSrc: firebase },
    { name: 'Flutter', imgSrc: flutter },
    { name: 'Teamwork', imgSrc: teamwork },
    { name: 'English', imgSrc: english },
    { name: 'Sinhala', imgSrc: sinhala },
  ],
};

export default function ExpertiseGrid() {
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const boxShadow = useColorModeValue('lg', 'dark-lg');

  return (
    <Box p={4} maxW="7xl" mx="auto">
      <Heading mb={6} fontSize="3xl" textAlign="center" color={textColor}>
        My Expertise
      </Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={6}>
        {Object.keys(expertiseData).map((category, index) => (
          <GridItem key={index} colSpan={1}>
            <VStack
              spacing={4}
              align="center"
              bg={bgColor}
              p={6}
              borderRadius="md"
              boxShadow={boxShadow}
              h="100%"
            >
              <Heading fontSize="xl" mb={4} textAlign="center" color={textColor}>
                {category}
              </Heading>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                {expertiseData[category].map((tech, idx) => (
                  <GridItem key={idx}>
                    <VStack spacing={2}>
                      <Image 
                        src={tech.imgSrc} 
                        alt={tech.name} 
                        width="50px" 
                        height="50px" 
                        objectFit="contain" 
                      />
                      <Text fontSize="sm" textAlign="center" color={textColor}>
                        {tech.name}
                      </Text>
                    </VStack>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}
